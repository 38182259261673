$(document).ready(function () {
    if (document.getElementById('resendResetPasswordForm')) {
        // resend reset password;
        var resend = {
            el: document.getElementById('resendResetPasswordForm'),
            init: function () {
                $(resend.el).on({
                    submit: function (e) {
                        var $form = $(this);
                        if ($form.data('submitted') === true) {
                            // Previously submitted - don't submit again
                            e.preventDefault();
                        } else {
                            // Mark it so that the next submit can be ignored
                            $form.data('submitted', true);
                        }
                    }
                });
            }
        };
        resend.init();
    }

    if (document.getElementById('resetpasswordForm')) {
        // reset password;
        var pw = {
            el: document.getElementById('resetpasswordForm'),
            $error: null,
            init: function () {
                pw.$error = $(pw.el).find('.whole-error-msg');
                pw.addEvent();
            },
            beforeSubmit: function (e) {
                e.preventDefault();

                var _this = e.currentTarget;
                var _fields = {
                    new: $(_this).find('#txtBoxPassword').get(0),
                    newConfirm: $(_this).find('#txtBoxPasswordConfirm').get(0)
                };

                if ($(_this).checkValidation()) {
                    // adobe
                    adobeTrackEvent('reset-password', {profile_update: "password", page_event: {update_profile: true}});
                    if (pw.check(_fields.new.value)) {
                        var $newBlock = $(_fields.new).closest('.field-block');
                        $newBlock.removeClass('error');
                        if (_fields.new.value == _fields.newConfirm.value) {
                            pw.$error.hide();
                            _this.submit();
                        } else {
                            pw.$error.show().find('.match').show().siblings().hide();
                        }
                    } else {
                        $('#txtBoxPassword').parents('.field-block').addClass('error')
                            .find('.error-msg .invalid').show().siblings().hide();
                    }
                }
            },
            check: function (val) {
            	var result = false;
    			var falseCnt = 0;
    			if (val.length > 7) {
    				var n = /^.*(?=.*\d).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n = /^.*(?=.*[a-z]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n = /^.*(?=.*[A-Z]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n= /^.*(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				
    				if(falseCnt > 1) {
    					result = false;
    				} else {
    					result = true;
    				}
    			}
    			return result;
            },
            addEvent: function () {
                $(pw.el).on({
                    submit: pw.beforeSubmit
                });
                $(pw.el).on({
                    keydown: function (e) {
                        if (e.keyCode == 13 && e.currentTarget.value != '') {
                            // console.log(pw.check(e.currentTarget.value));
                            if (!pw.check($('#txtBoxPassword').val())) {
                                e.preventDefault();
                                $('#txtBoxPassword').parents('.field-block').addClass('error')
                                    .find('.error-msg .invalid').show().siblings().hide();
                            }
                        }
                    },
                    /* Start of LGEUS-11769 */
                    keyup: function (e) {
                        if (e.currentTarget.value == '' || pw.check($('#txtBoxPassword').val())) {
                            // remove password requirement
                            e.preventDefault();
                            $('#txtBoxPassword').parents('.field-block').removeClass('error');
                        }
                    }
                    /* End of LGEUS-11769 */
                }, '#txtBoxPassword, #txtBoxPasswordConfirm');
            }
        };
        pw.init();
    }
    
    if (document.getElementById('createscpasswordForm')) {
        // reset password;
        var pw = {
            el: document.getElementById('createscpasswordForm'),
            $error: null,
            init: function () {
                pw.$error = $(pw.el).find('.whole-error-msg');
                pw.addEvent();
            },
            checkPassword: function(){
    			var _this = pw.el;
    			var _fields = {
    				password: $(_this).find('#txtBoxPassword').get(0),
    				passwordConfirm: $(_this).find('#txtBoxPasswordConfirm').get(0)
    			};
    			var $block = $(_fields.password).parents('.field-block'),
    				$confirmBlock = $(_fields.passwordConfirm).parents('.field-block');
    			var result = true;

    			if(_fields.password.value != '' && pw.checkPasswordPattern(_fields.password.value)) {
    				if(_fields.password.value == _fields.passwordConfirm.value) {
    					$confirmBlock.removeClass('error');
    				}else {
    					$(_fields.passwordConfirm).focus();
    					$confirmBlock.addClass('error').find('.match').show().siblings().hide();
    					result = false;
    				}
    				$block.removeClass('error');
    			}else {
    				$(_fields.password).focus();
    				$block.addClass('error').find('.invalid').show().siblings().hide();
    				result = false;
    			}

    			return result;
    		},
    		checkPasswordPattern: function(val){
    			var result = false;
    			var falseCnt = 0;
    			if (val.length > 7) {
    				var n = /^.*(?=.*\d).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n = /^.*(?=.*[a-z]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n = /^.*(?=.*[A-Z]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n= /^.*(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				
    				if(falseCnt > 1) {
    					result = false;
    				} else {
    					result = true;
    				}
    			}
    			return result;
    		},
            beforeSubmit: function (e) {
                e.preventDefault();

                $('#txtBoxPassword').parents('.field-block').removeClass('error');
                $('#txtBoxPasswordConfirm').parents('.field-block').removeClass('error');
                
                var _this = e.currentTarget;
                var _fields = {
                    new: $(_this).find('#txtBoxPassword').get(0),
                    newConfirm: $(_this).find('#txtBoxPasswordConfirm').get(0)
                };

                if ($(_this).checkValidation() && pw.checkPassword(_fields.new.value)) {
                    // adobe
                    adobeTrackEvent('create-password', {profile_update: "password", page_event: {update_profile: true}});
                    var $newBlock = $(_fields.new).closest('.field-block');
                    $newBlock.removeClass('error');
                    if (_fields.new.value == _fields.newConfirm.value) {
                        _this.submit();
                    } else {
                        pw.$error.show().find('.match').show().siblings().hide();
                    }
                }
            },
            addEvent: function () {
                $(pw.el).on({
                    submit: pw.beforeSubmit
                });
                $(pw.el).on({
                	keydown: function(e){
    					if(e.keyCode==13 && e.currentTarget.value != '') {
    						
    						var elId = $(e.target).attr('id');
    						
    						if(elId == "txtBoxPassword") {
    							if(!pw.checkPassword()) {
    								e.preventDefault();
    							}
    						}
    					}
    				},
                    /* Start of LGEUS-11769 */
    				keyup: function (e) {
    					var _this = pw.el;
    					var _fields = {
    						password: $(_this).find('#txtBoxPassword').get(0)
    					};
    					
    					var $block = $(_fields.password).parents('.field-block');
    					var elId = $(e.target).attr('id');
    					
    					if(elId == "txtBoxPassword") {
    						if (e.currentTarget.value == '' || pw.checkPasswordPattern(_fields.password.value)) {
    							// remove password requirement
    							$block.removeClass('error');
    						}
    					}
    				}
                    /* End of LGEUS-11769 */
                }, 'input[type="password"]');
            }
        };
        pw.init();
    }

    if (document.querySelector('.order-validation-form')) {
        var order = {
            el: document.querySelector('.order-validation-form'),
            init: function () {
                $(order.el).on({
                    submit: function (e) {
                        e.preventDefault();
                        var _this = e.currentTarget;
                        if ($(_this).checkValidation()) {
                            var url = _this.getAttribute('data-url');
                            var param = xssfilter($(_this).serialize());
                            var methodType = $(_this).data('ajaxMethod');

                            $('body').trigger('ajaxLoadBefore');
                            ajax.call(url, param, 'json', function (data) {
                                $('body').trigger('ajaxLoadEnd');
                                if (!data.result) {
                                    var key = Object.keys(data.error);
                                    var $target = $(_this[key]).closest('.field-block');
                                    $target.addClass('error').find('.unregistered').text(data.error[key[0]]).show().siblings().hide();
                                    // $(_this[key]).text(data.error[key[0]])
                                }
                            }, methodType);
                        }
                    }
                });
            }
        };

        order.init();
    }
    if (document.querySelector('.integration-wrap')) {
		var timer;
		var form = {
    		el: document.getElementById('empCombileForm'),
			timerInterval: null,
			totalSeconds: parseInt($('#initSec').val()),
    		init: function(){
    			form.addEvent();
    		},
			checkFName: function(){
				var _this = form.el;
				var _fields = {
					firstname: $(_this).find('#txtBoxFName').get(0)
				};
				var $fBlock = $(_fields.firstname).parents('.field-block');
				var result = true;
	
				if(_fields.firstname.value != '' && !form.checkNamePattern(_fields.firstname.value)) {
					$fBlock.removeClass('error');
				}else {
					if(_fields.firstname.value != '') {
						$(_fields.firstname).focus();
						$fBlock.addClass('error').find('.invalid').show().siblings().hide();
					}
					result = false;
				}
				
				return result;
			},
			checkLName: function(){
				var _this = form.el;
				var _fields = {
					lastname: $(_this).find('#txtBoxLName').get(0)
				};
				var $lBlock = $(_fields.lastname).parents('.field-block');
				var result = true;
	
				if(_fields.lastname.value != '' && !form.checkNamePattern(_fields.lastname.value)) {
					$lBlock.removeClass('error');
				}else {
					if(_fields.lastname.value != '') {
						$(_fields.lastname).focus();
						$lBlock.addClass('error').find('.invalid').show().siblings().hide();
					}
					result = false;
				}
	
				return result;
			},
			checkNamePattern: function(val){
				var result = false;
				var n = /[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/;
				result = n.test(val);
				return result;
			},
    		checkPassword: function(){
    			var _this = form.el;
    			var _fields = {
    				oldPassword: $(_this).find('#txtBoxOldPassword').get(0),
    				password: $(_this).find('#txtBoxPassword').get(0),
    				passwordConfirm: $(_this).find('#txtBoxPasswordConfirm').get(0)
    			};
    			var $block = $(_fields.password).parents('.field-block'),
    				$confirmBlock = $(_fields.passwordConfirm).parents('.field-block');
    			var result = true;

    			if(_fields.password.value != '' && form.checkPasswordPattern(_fields.password.value)) {
    				if(_fields.password.value == _fields.passwordConfirm.value) {
    					$confirmBlock.removeClass('error');
    				}else {
    					$(_fields.passwordConfirm).focus();
    					$confirmBlock.addClass('error').find('.match').show().siblings().hide();
    					result = false;
    				}
    				$block.removeClass('error');
    			}else if(_fields.oldPassword.value != ''&& _fields.oldPassword.value == _fields.password.value){
    				$(_fields.password).focus();
    				$block.addClass('error').find('.ajax-msg').show().siblings().hide();
    				result = false;
    			}else {
    				$(_fields.password).focus();
    				$block.addClass('error').find('.invalid').show().siblings().hide();
    				result = false;
    			}
    			

    			return result;
    		},
    		checkPasswordPattern: function(val){
    			var result = false;
    			var falseCnt = 0;
    			if (val.length > 7) {
    				var n = /^.*(?=.*\d).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n = /^.*(?=.*[a-z]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n = /^.*(?=.*[A-Z]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n= /^.*(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				
    				if(falseCnt > 1) {
    					result = false;
    				} else {
    					result = true;
    				}
    			}
    			return result;
    		},
    		checkBirthDate: function(){
    			var _this = form.el;
    			var _fields = {
    				birthDate: $(_this).find('#requestDate').get(0)
    			};
    			var $block = $(_fields.birthDate).parents('.field-block')
    			var result = true;
    			if(document.querySelector('#requestDate')){
	    			if(form.checkBirthDatePattern(_fields.birthDate.value)){
	    				$block.addClass('error').find('.invalid').show().siblings().hide();
	    				result = false;
	    			}
    			}
    			return result;
    		},
    		checkBirthDatePattern: function(val){
    			var birthDate = val.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
    			var birthday = new Date(birthDate);
    			var today = new Date();
    			var years = today.getFullYear() - birthday.getFullYear();
    			birthday.setFullYear(today.getFullYear());
    			if (today < birthday) years--;
    			var result = false;
    			if (years < 16) {
    				result = true;
    			}
    			return result;
    		},
			updateTimerDisplay: function(minutes, seconds){
    			var formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
				var formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
				$('#otpTimer').text(formattedMinutes + ':' + formattedSeconds);
    		},
			startTimer: function(){
    			timerInterval = setInterval(function() {
					var minutes = Math.floor(form.totalSeconds / 60);
					var seconds = form.totalSeconds % 60;
	
					form.updateTimerDisplay(minutes, seconds);
	
					if (form.totalSeconds <= 0) {
						clearInterval(timerInterval);
					} else {
						form.totalSeconds--;
					}
					
				}, 1000);
    		},
			resetTimer: function() {
				clearInterval(form.timerInterval);
				form.totalSeconds = parseInt($('#initSec').val());
				form.updateTimerDisplay(Math.floor(form.totalSeconds / 60), form.totalSeconds % 60);
				form.startTimer();
			},
    		beforeSubmit: function(e){
    			e.preventDefault();

    			var _this = e.currentTarget;
    			
    			if($(_this).checkValidation() && form.checkPassword() && form.checkBirthDate()) {
    				var url = form.el.getAttribute('data-url');
    				if(!url){
    			 		
    				}else{
    					var param = xssfilter($(form.el).serialize());
    					var methodType = $(form.el).data('ajaxMethod');
    					$('body').trigger('ajaxLoadBefore');
    					ajax.noCacheCall(url, param, 'json', function(data){
    						$('body').trigger('ajaxLoadEnd');
    					 	if(!data.result) {
    					 		if(data.returnData.resultCode && data.returnData.resultCode == 5){
    					 			$('#txtBoxOldPassword').parents('.field-block').addClass('error')
    	                            .find('.error-msg .invalid').show().siblings().hide();
    					 		}else if(data.returnData.resultCode && (data.returnData.resultCode == 6||data.returnData.resultCode == 8)){
    					 			$('#txtBoxPassword').parents('.field-block').addClass('error')
    	                            .find('.error-msg .invalid').show().siblings().hide();
    					 		}else if(data.returnData.resultMessage){
    					 			$('#modal_user_registration_fail .simple-content-box .content-paragraph:first').html(data.returnData.resultMessage);
    					 			$('#modal_user_registration_fail').modal('show');
    					 		}else{
    					 			$('#modal_user_registration_fail').modal('show');
    					 		}
    					 	}else {
    					 		//$('.step2 dd.integration-email').html(data.email);
								$('#resendOtpVerificationForm').attr('data-url', data.otpVerifyUrl);
    					 		$(".container-fluid.step1").css('display', 'none');
    					 		$(".container-fluid.step2").css('display', 'block');
    					 		window.scrollTo(0,0);
					
								form.startTimer();
    					 	}
    					 },methodType);
    				}
    				
                    
    			}
    		},
    		addEvent: function(){
    			$(form.el).on({
    				submit: form.beforeSubmit
    			});
    			$(form.el).on({
    				keydown: function(e){
    					if(e.keyCode==13 && e.currentTarget.value != '') {
							var elId = $(e.target).attr('id');
    						if(elId == "txtBoxPassword") {
								if(!form.checkPassword()) {
									e.preventDefault();
								}
							} else if(elId == "txtBoxPasswordConfirm") {
								if(!form.checkPassword()) {
									e.preventDefault();
								}
							} else if(elId == "txtBoxFName") {
								if(!form.checkFName()) {
									e.preventDefault();
								}
							} else if(elId == "txtBoxLName") {
								if(!form.checkLName()) {
									e.preventDefault();
								}
							}
    					}
    				},
                    /* Start of LGEUS-11769 */
    				keyup: function (e) {
    					var _this = form.el;
    					var _fields = {
    						password: $(_this).find('#txtBoxPassword').get(0),
							firstname: $(_this).find('#txtBoxFName').get(0),
							lastname: $(_this).find('#txtBoxLName').get(0)
    					};
    					var $block = $(_fields.password).parents('.field-block'),
							$fBlock = $(_fields.firstname).parents('.field-block'),
							$lBlock = $(_fields.lastname).parents('.field-block');

						var elId = $(e.target).attr('id');

						if(elId == "txtBoxPassword") {
							if (e.currentTarget.value == '' || form.checkPasswordPattern(_fields.password.value)) {
								// remove password requirement
								$block.removeClass('error');
							}
						} else if(elId == "txtBoxFName") {
							if (e.currentTarget.value == '') {
								// remove password requirement
								$fBlock.removeClass('error');
							}
							if (form.checkNamePattern(_fields.firstname.value)) {
								var input = _fields.firstname.value;
								$(this).val(input.replace(/[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/g, ''));
							}
						} else if(elId == "txtBoxLName") {
							if (e.currentTarget.value == '') {
								// remove password requirement
								$lBlock.removeClass('error');
							}
							if (form.checkNamePattern(_fields.lastname.value)) {
								var input = _fields.firstname.value;
								$(this).val(input.replace(/[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/g, ''));
							}
						}
    				}
                    /* End of LGEUS-11769 */
    			}, 'input[type="password"],#txtBoxFName,#txtBoxLName');
    			$(form.el).on({
    				change: function (e) {
    					var disableFlag = false;
    					var input = $(form.el).find('input');
    					input.each(function(index){
    						var val = '';
    						if(input.eq(index).attr('type')=='checkbox'){
    							if(input.eq(index).is(":checked")==true){
    								val = input.eq(index).val().trim();
    							}
    						}else{
	    						val = input.eq(index).val().trim();
	    					}
    						if(val != '') {
    							disableFlag = true;
    						}else{
    							disableFlag = false;
    							$('button[type="submit"]').attr('disabled', true);
    							return false;
    						}
    					})
    					if(disableFlag){
    						$('button[type="submit"]').attr('disabled', false); 
    					}
    				}
    			}, 'input');
    		}
    	};

    	form.init();

		var bForm = {
    		el: document.getElementById('empCombileBForm'),
			timerInterval: null,
			totalSeconds: parseInt($('#initSec').val()),
    		init: function(){
    			bForm.addEvent();
    		},
    		checkPasswordPattern: function(val){
    			var result = false;
    			var falseCnt = 0;
    			if (val.length > 7) {
    				var n = /^.*(?=.*\d).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n = /^.*(?=.*[a-z]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n = /^.*(?=.*[A-Z]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				n= /^.*(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]).*$/;
    				result = n.test(val);
    				if(!result) {
    					falseCnt++;
    				}
    				
    				if(falseCnt > 1) {
    					result = false;
    				} else {
    					result = true;
    				}
    			}
    			return result;
    		},
    		checkBirthDate: function(){
    			var _this = bForm.el;
    			var _fields = {
    				birthDate: $(_this).find('#requestDate').get(0)
    			};
    			var $block = $(_fields.birthDate).parents('.field-block')
    			var result = true;
    			if(document.querySelector('#requestDate')){
	    			if(bForm.checkBirthDatePattern(_fields.birthDate.value)){
	    				$block.addClass('error').find('.invalid').show().siblings().hide();
	    				result = false;
	    			}
    			}
    			return result;
    		},
    		checkBirthDatePattern: function(val){
    			var birthDate = val.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
    			var birthday = new Date(birthDate);
    			var today = new Date();
    			var years = today.getFullYear() - birthday.getFullYear();
    			birthday.setFullYear(today.getFullYear());
    			if (today < birthday) years--;
    			var result = false;
    			if (years < 16) {
    				result = true;
    			}
    			return result;
    		},
			updateTimerDisplay: function(minutes, seconds) {
				var formattedMinutes = '';
				var formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
				if(minutes == null || minutes <= 0) {
					$('#timer').text(seconds + " seconds");
				} else {
					minutes < 10 ? '0' + minutes : minutes;
					$('#timer').text(formattedMinutes + ':' + formattedSeconds);
				}
			},
			startTimer: function() {
				bForm.timerInterval = setInterval(function() {
					var minutes = Math.floor(bForm.totalSeconds / 60);
					var seconds = bForm.totalSeconds % 60;
	
					bForm.updateTimerDisplay(minutes, seconds);
	
					if (bForm.totalSeconds <= 0) {
						clearInterval(bForm.timerInterval);
						window.location.href = "/us";
					}
	
					bForm.totalSeconds--;
				}, 1000);
			},
    		beforeSubmit: function(e){
    			e.preventDefault();

    			var _this = e.currentTarget;
    			grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'accountCombine'}).then(function(token) {
					if(token) {
						$('#reCaptchaToken').val(token);

						if($(_this).checkValidation() && bForm.checkBirthDate()) {
							var url = bForm.el.getAttribute('data-url');
							if(!url){
								
							}else{
								
								var param = xssfilter($(bForm.el).serialize());
								var methodType = $(bForm.el).data('ajaxMethod');
								$('body').trigger('ajaxLoadBefore');
								ajax.noCacheCall(url, param, 'json', function(data){
									$('body').trigger('ajaxLoadEnd');
									if(!data.result) {
										if(data.returnData.resultCode && data.returnData.resultCode == 5){
											$('#txtBoxOldPassword').parents('.field-block').addClass('error')
											.find('.error-msg .invalid').show().siblings().hide();
										}else if(data.returnData.resultMessage){
											$('#modal_user_registration_fail .simple-content-box .content-paragraph:first').html(data.returnData.resultMessage);
											$('#modal_user_registration_fail').modal('show');
										}else{
											$('#modal_user_registration_fail').modal('show');
										}
									}else {
										$(".container-fluid.step1").css('display', 'none');
										$(".container-fluid.step2").css('display', 'block');
										bForm.startTimer();
										window.scrollTo(0,0);
									}
								},methodType);
							}
						}
					} else {
						//$('.otpField .error-msg').show().find('.invalid-token').show().siblings().hide();
					}
				});
    		},
    		addEvent: function(){
    			$(bForm.el).on({
    				submit: bForm.beforeSubmit
    			});
    			$(bForm.el).on({
    				keydown: function(e){
    					if(e.keyCode==13 && e.currentTarget.value != '') {
    						if(!bForm.checkPassword()) {
    							e.preventDefault();
    						}
    					}
    				},
                    /* Start of LGEUS-11769 */
    				keyup: function (e) {
    					var _this = bForm.el;
    					var _fields = {
    						password: $(_this).find('#txtBoxOldPassword').get(0)
    					};
    					var $block = $(_fields.password).parents('.field-block');

    					if (e.currentTarget.value == '' || bForm.checkPasswordPattern(_fields.password.value)) {
    						// remove password requirement
    						$block.removeClass('error');
    						
    					}
    				}
                    /* End of LGEUS-11769 */
    			}, 'input[type="txtBoxOldPassword"]');
    			$(bForm.el).on({
    				change: function (e) {
    					var disableFlag = false;
    					var input = $(bForm.el).find('input');
    					input.each(function(index){
    						var val = '';
							if(input.eq(index).attr('id')!='reCaptchaToken'){
								if(input.eq(index).attr('type')=='checkbox'){
									if(input.eq(index).is(":checked")==true){
										val = input.eq(index).val().trim();
									}
								}else{
									val = input.eq(index).val().trim();
								}
								if(val != '') {
									disableFlag = true;
								}else{
									disableFlag = false;
									$('button[type="submit"]').attr('disabled', true);
									return false;
								}
							}
    					})
    					if(disableFlag){
    						$('button[type="submit"]').attr('disabled', false); 
    					}
    				}
    			}, 'input');
    		}
    	};

    	bForm.init();
		
		var resendOtpVerificationForm = {
			el: document.getElementById('resendOtpVerificationForm'),
			timerInterval: null,
			totalSeconds: parseInt($('#redirectTimer').text().trim()),
			updateTimerDisplay : function() {
                $('#redirectTimer').text(resendOtpVerificationForm.totalSeconds);

				if(resendOtpVerificationForm.totalSeconds == 0) {
					clearInterval(resendOtpVerificationForm.timerInterval);
					grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'accountCombine'}).then(function(token) {
						if(token) {
							$('.step3 #reCaptchaToken').val(token);
							$('#verificationCompleteForm').submit();
						} 
					});
				}

				resendOtpVerificationForm.totalSeconds--;
            },
			startTimer : function() {
                resendOtpVerificationForm.timerInterval = setInterval(function() {
                    resendOtpVerificationForm.updateTimerDisplay();
                }, 1000);
            },
			init: function(){
				resendOtpVerificationForm.addEvent();
			},
			beforeSubmit: function(e){
				e.preventDefault();
				$('.error-msg').hide();
				if(form.totalSeconds > 0) {
					var otpCode = $('#otpCode').val().replace("-","");
					if(otpCode.length == 6) {
						// grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'accountCombine'}).then(function(token) {
						// 	if(token) {
						// 		$('#reCaptchaToken').val(token);

								var url = resendOtpVerificationForm.el.getAttribute('data-url') + "&activateKey=" + otpCode;
								var methodType = $(resendOtpVerificationForm.el).data('ajaxMethod');
								$('body').trigger('ajaxLoadBefore');
								ajax.noCacheCall(url, {}, 'json', function(data){
									if(data.result) {
										var otpVerificationResult = data.otpVerificationResult
										
										if(otpVerificationResult) {
											$('body').trigger('ajaxLoadEnd');
											$(".container-fluid.step2").css('display', 'none');
											$(".container-fluid.step3").css('display', 'block');
											$('.step3 input[name=dkdlel]').val($('.step2 p.integration-email').text().trim());
											$('.step3 input[name=dkagh]').val($('.step1 #txtBoxPassword').val());
											resendOtpVerificationForm.startTimer();
											/*setTimeout(function() {
												$('.step2 input[name=dkdlel]').val($('.step2 p.integration-email').text().trim());
												$('.step2 input[name=dkagh]').val($('.step1 #txtBoxPassword').val());
												resendOtpVerificationForm.el.submit();
											}, 100);*/
										} else {
											$('.otpField .error-msg').show().find('.sys-error').show().siblings().hide();
											$('body').trigger('ajaxLoadEnd');
										}
									} else {
										$('.otpField .error-msg').show().find('.sys-error').show().siblings().hide();
										$('body').trigger('ajaxLoadEnd');
									}
								},methodType);
						// 	} else {
						// 		$('.otpField .error-msg').show().find('.invalid-token').show().siblings().hide();
						// 	}
						// });	
					} else {
						if(otpCode.length < 6 || otpCode.length > 6) {
							$('.otpField .error-msg').show().find('.min-length').show().siblings().hide();
						}

						if(otpCode=="") {
							$('.error-msg').show().find('.required').show().siblings().hide();
						}
					}
				} else {
					$('.error-msg').show().find('.timeout').show().siblings().hide();
				}
			},
			addEvent: function(){
				$(resendOtpVerificationForm.el).on({
					submit: resendOtpVerificationForm.beforeSubmit
				});

				$('#otpCode').on("focus", function(){
					$('.otpField .error-msg').children().hide();
				});
			}
		};

    	resendOtpVerificationForm.init();

		var combineDCompletedForm = {
			el: document.getElementById('combineDCompletedForm'),
			timerInterval: null,
			totalSeconds: parseInt($('#redirectTimer').text().trim()),
			updateTimerDisplay : function() {
                $('#redirectTimer').text(combineDCompletedForm.totalSeconds);

				if(combineDCompletedForm.totalSeconds == 0) {
					clearInterval(combineDCompletedForm.timerInterval);
					grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'accountCombineD'}).then(function(token) {
						if(token) {
							$('.step2-combined #reCaptchaToken').val(token);
							$('#combineDCompletedForm').submit();
						} 
					});
				}

				combineDCompletedForm.totalSeconds--;
            },
			startTimer : function() {
                combineDCompletedForm.timerInterval = setInterval(function() {
                    combineDCompletedForm.updateTimerDisplay();
                }, 1000);
            },
			init: function(){
				combineDCompletedForm.addEvent();
			},
			beforeSubmit: function(e){
				e.preventDefault();
				$('.error-msg').hide();
				grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'accountCombineDSignin'}).then(function(token) {
					if(token) {
						$('#reCaptchaToken').val(token);
						combineDCompletedForm.el.submit();
					}
				});	
			},
			addEvent: function(){
				$(combineDCompletedForm.el).on({
					submit: combineDCompletedForm.beforeSubmit
				});
			}
		};

    	combineDCompletedForm.init();
    	
    	var accountConbineDForm = {
			el: document.getElementById('accountConbineDForm'),
			init: function(){
				accountConbineDForm.addEvent();
			},
			checkPassword: function(){
				var _this = accountConbineDForm.el;
				var _fields = {
					password: $(_this).find('#txtBoxPassword').get(0),
					passwordConfirm: $(_this).find('#txtBoxPasswordConfirm').get(0)
				};
				var $block = $(_fields.password).parents('.field-block'),
				$confirmBlock = $(_fields.passwordConfirm).parents('.field-block');
				var result = true;
	
				if(_fields.password.value != '' && accountConbineDForm.checkPasswordPattern(_fields.password.value)) {
					if(_fields.password.value == _fields.passwordConfirm.value) {
						$confirmBlock.removeClass('error');
					}else {
						$(_fields.passwordConfirm).focus();
						$confirmBlock.addClass('error').find('.match').show().siblings().hide();
						result = false;
					}
					$block.removeClass('error');
				}else {
					$(_fields.password).focus();
					$block.addClass('error').find('.invalid').show().siblings().hide();
					result = false;
				}
	
				return result;
			},
			checkPasswordPattern: function(val){
				var result = false;
				var falseCnt = 0;
				if (val.length > 7) {
					var n = /^.*(?=.*\d).*$/;
					result = n.test(val);
					if(!result) {
						falseCnt++;
					}
					
					n = /^.*(?=.*[a-z]).*$/;
					result = n.test(val);
					if(!result) {
						falseCnt++;
					}
					
					n = /^.*(?=.*[A-Z]).*$/;
					result = n.test(val);
					if(!result) {
						falseCnt++;
					}
					
					n= /^.*(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]).*$/;
					result = n.test(val);
					if(!result) {
						falseCnt++;
					}
		
					if(falseCnt > 1) {
						result = false;
					} else {
						result = true;
					}
				}
				return result;
			},
			checkBirthDate: function(){
				var _this = accountConbineDForm.el;
				var _fields = {
						birthDate: $(_this).find('#requestDate').get(0)
				};
				var $block = $(_fields.birthDate).parents('.field-block')
				var result = true;
				if(document.querySelector('#requestDate')){
					if(accountConbineDForm.checkBirthDatePattern(_fields.birthDate.value)){
						$block.addClass('error').find('.invalid').show().siblings().hide();
						result = false;
					}
				}
				return result;
			},
			checkBirthDatePattern: function(val){
				var birthDate = val.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");
				var birthday = new Date(birthDate);
				var today = new Date();
				var years = today.getFullYear() - birthday.getFullYear();
				birthday.setFullYear(today.getFullYear());
				if (today < birthday) years--;
				var result = false;
				if (years < 16) {
					result = true;
				}
				return result;
			},
			beforeSubmit: function(e){
				e.preventDefault();
	
				var _this = e.currentTarget;
	
				if($(_this).checkValidation() && accountConbineDForm.checkPassword() && accountConbineDForm.checkBirthDate()) {
					var url = accountConbineDForm.el.getAttribute('data-url');
					if(!url){
		
					}else{
						var param = xssfilter($(accountConbineDForm.el).serialize());
						var methodType = $(accountConbineDForm.el).data('ajaxMethod');
						$('body').trigger('ajaxLoadBefore');
						ajax.noCacheCall(url, param, 'json', function(data){
							$('body').trigger('ajaxLoadEnd');
							if(!data.result) {
								if(data.returnData.resultCode && data.returnData.resultCode == 5){
									$('#txtBoxOldPassword').parents('.field-block').addClass('error')
									.find('.error-msg .invalid').show().siblings().hide();
								}else if(data.returnData.resultCode && (data.returnData.resultCode == 6||data.returnData.resultCode == 8)){
									$('#txtBoxPassword').parents('.field-block').addClass('error')
									.find('.error-msg .invalid').show().siblings().hide();
								}else if(data.returnData.resultMessage){
									$('#modal_user_registration_fail .simple-content-box .content-paragraph:first').html(data.returnData.resultMessage);
									$('#modal_user_registration_fail').modal('show');
								}else{
									$('#modal_user_registration_fail').modal('show');
								}
							}else {
								$(".container-fluid.step1-combined").css('display', 'none');
								$(".container-fluid.step2-combined").css('display', 'block');

								$('.step2-combined input[name=dkdlel]').val($('.step1-combined #empEmailAddr').val().trim());
								$('.step2-combined input[name=dkagh]').val($('.step1-combined #txtBoxPassword').val());

								combineDCompletedForm.startTimer();

								window.scrollTo(0,0);
							}
						},methodType);
					}
				}
			},
			addEvent: function(){
				$(accountConbineDForm.el).on({
					submit: accountConbineDForm.beforeSubmit
				});
				$(accountConbineDForm.el).on({
					keydown: function(e){
						if(e.keyCode==13 && e.currentTarget.value != '') {
							if(!accountConbineDForm.checkPassword()) {
								e.preventDefault();
							}
						}
					},
					/* Start of LGEUS-11769 */
					keyup: function (e) {
						var _this = accountConbineDForm.el;
						var _fields = {
								password: $(_this).find('#txtBoxPassword').get(0)
						};
						var $block = $(_fields.password).parents('.field-block');
			
						if (e.currentTarget.value == '' || accountConbineDForm.checkPasswordPattern(_fields.password.value)) {
							// remove password requirement
							$block.removeClass('error');
						}
					}
				/* End of LGEUS-11769 */
				}, 'input[type="password"]');
				$(accountConbineDForm.el).on({
					change: function (e) {
						var disableFlag = false;
						var input = $(accountConbineDForm.el).find('input');
						input.each(function(index){
							var val = '';
							if(input.eq(index).attr('type')=='checkbox'){
								if(input.eq(index).is(":checked")==true){
									val = input.eq(index).val().trim();
								}
							}else{
								val = input.eq(index).val().trim();
							}
							if(val != '') {
								disableFlag = true;
							}else{
								disableFlag = false;
								$('button[type="submit"]').attr('disabled', true);
								return false;
							}
						})
						if(disableFlag){
							$('button[type="submit"]').attr('disabled', false); 
						}
					}
				}, 'input');
			}
		};

    	accountConbineDForm.init();

    	var accountConbineThinqForm = {
			el: document.getElementById('accountConbineThinqForm'),
			init: function(){
				accountConbineThinqForm.addEvent();
			},
			checkPassword: function(){
				var _this = accountConbineThinqForm.el;
				var _fields = {
						password: $(_this).find('#txtBoxPassword').get(0),
						passwordConfirm: $(_this).find('#txtBoxPasswordConfirm').get(0)
				};
				var $block = $(_fields.password).parents('.field-block'),
				$confirmBlock = $(_fields.passwordConfirm).parents('.field-block');
				var result = true;
				
				if(_fields.password.value != '' && accountConbineThinqForm.checkPasswordPattern(_fields.password.value)) {
					if(_fields.password.value == _fields.passwordConfirm.value) {
						$confirmBlock.removeClass('error');
					}else {
						$(_fields.passwordConfirm).focus();
						$confirmBlock.addClass('error').find('.match').show().siblings().hide();
						result = false;
					}
					$block.removeClass('error');
				}else if(_fields.oldPassword.value != ''&& _fields.oldPassword.value == _fields.password.value){
					$(_fields.password).focus();
					$block.addClass('error').find('.ajax-msg').show().siblings().hide();
					result = false;
				}else {
					$(_fields.password).focus();
					$block.addClass('error').find('.invalid').show().siblings().hide();
					result = false;
				}
				
				return result;
			},
			checkPasswordPattern: function(val){
				var result = false;
				var falseCnt = 0;
				if (val.length > 7) {
					var n = /^.*(?=.*\d).*$/;
					result = n.test(val);
					if(!result) {
						falseCnt++;
					}
					
					n = /^.*(?=.*[a-z]).*$/;
					result = n.test(val);
					if(!result) {
						falseCnt++;
					}
					
					n = /^.*(?=.*[A-Z]).*$/;
					result = n.test(val);
					if(!result) {
						falseCnt++;
					}
					
					n= /^.*(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]).*$/;
					result = n.test(val);
					if(!result) {
						falseCnt++;
					}
					
					if(falseCnt > 1) {
						result = false;
					} else {
						result = true;
					}
				}
				return result;
			},
			beforeSubmit: function(e){
				e.preventDefault();
				
				var _this = e.currentTarget;
				
				//if($(_this).checkValidation() && accountConbineThinqForm.checkPassword() && accountConbineThinqForm.checkBirthDate()) {
				if($(_this).checkValidation() && accountConbineThinqForm.checkPassword()) {
					var url = accountConbineThinqForm.el.getAttribute('data-url');
					if(!url){
						
					}else{
						var param = xssfilter($(accountConbineThinqForm.el).serialize());
						var methodType = $(accountConbineThinqForm.el).data('ajaxMethod');
						$('body').trigger('ajaxLoadBefore');
						ajax.noCacheCall(url, param, 'json', function(data){
							$('body').trigger('ajaxLoadEnd');
							if(!data.result) {
								if(data.returnData.resultCode && data.returnData.resultCode == 5){
									$('#txtBoxOldPassword').parents('.field-block').addClass('error')
									.find('.error-msg .invalid').show().siblings().hide();
								}else if(data.returnData.resultCode && (data.returnData.resultCode == 6||data.returnData.resultCode == 8)){
									$('#txtBoxPassword').parents('.field-block').addClass('error')
									.find('.error-msg .invalid').show().siblings().hide();
								}else{
									$('#modal_user_registration_fail').modal('show');
								}
							}else {
								$('.step2-combined dd.integration-email').html(data.email);
								$(".container-fluid.step1-combined").css('display', 'none');
								$(".container-fluid.step2-combined").css('display', 'block');
								window.scrollTo(0,0);
							}
						},methodType);
					}
				}
			},
			addEvent: function(){
				$(accountConbineThinqForm.el).on({
					submit: accountConbineThinqForm.beforeSubmit
				});
				$(accountConbineThinqForm.el).on({
					keydown: function(e){
						if(e.keyCode==13 && e.currentTarget.value != '') {
							if(!accountConbineThinqForm.checkPassword()) {
								e.preventDefault();
							}
						}
					},
					/* Start of LGEUS-11769 */
					keyup: function (e) {
						var _this = accountConbineThinqForm.el;
						var _fields = {
								password: $(_this).find('#txtBoxPassword').get(0)
						};
						var $block = $(_fields.password).parents('.field-block');
						
						if (e.currentTarget.value == '' || accountConbineThinqForm.checkPasswordPattern(_fields.password.value)) {
							// remove password requirement
							$block.removeClass('error');
						}
					}
					/* End of LGEUS-11769 */
				}, 'input[type="password"]');
				$(accountConbineThinqForm.el).on({
					change: function (e) {
						var disableFlag = false;
						var input = $(accountConbineThinqForm.el).find('input');
						input.each(function(index){
							var val = '';
							if(input.eq(index).attr('type')=='checkbox'){
								if(input.eq(index).is(":checked")==true){
									val = input.eq(index).val().trim();
								}
							}else{
								val = input.eq(index).val().trim();
							}
							if(val != '') {
								disableFlag = true;
							}else{
								disableFlag = false;
								$('button[type="submit"]').attr('disabled', true);
								return false;
							}
						})
						if(disableFlag){
							$('button[type="submit"]').attr('disabled', false); 
						}
					}
				}, 'input');
			}
    	};
    	
    	accountConbineThinqForm.init();

		if ( !!$('.resendOtpVerificationEmail').length ){
			$('a.resendOtpVerificationEmail').on({
				click: function (e) {
					e.preventDefault();
					var _this = $('.resendOtpVerificationEmail');
					var url =  _this.attr('data-url');
					var email = $('.step2 p.integration-email').text().trim();
					var methodType = $(_this).data('ajaxMethod');
					$('body').trigger('ajaxLoadBefore');
					ajax.noCacheCall(url, { "emailAddr": decodeURIComponent(xssfilter(email)), "emailType": "combineOtp" }, 'json', function (data) {
						$('body').trigger('ajaxLoadEnd');
						//$("#txtBoxEmail").siblings('.error-msg').find('span').hide();
						if (data.mailSendFlag =='Y') {
							$('#unregistered-resend').modal('show');
							form.resetTimer();
							$('.step2 .verify-otp').attr("disabled", false);
						}else{
							$('#systemError').modal('show');
						}
					}, methodType);
				}
			});
		}
    }
    
    if (document.querySelector('.combinePasswordCheck-wrap')) {
        var empCombineForm = {
        		el: document.getElementById('checkPasswordForm'),
        		reset : document.getElementById('reset-emp-password'),
        		firstName: "",
        		lastName: "",
        		birthDay: "",
        		init: function(){
        			empCombineForm.addEvent();
        		},
        		parseJwt : function() {
        			var cookie = getCookie('ACCESS_TOKEN');
        			var base64Url = cookie.split('.')[1];
        		    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        		    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        		        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        		    }).join(''));

        		    var resultJson = JSON.parse(jsonPayload);
        		    empCombineForm.firstName = resultJson.context.user.first_name;
        		    empCombineForm.lastName = resultJson.context.user.last_name;
        		    empCombineForm.birthDay = resultJson.context.user.birth_date;
        		},
        		checkPassword: function(){
        			var _this = empCombineForm.el;
        			var _fields = {
        				password: $(_this).find('#txtBoxPasswd').get(0)
        			};
        			var $block = $(_fields.password).parents('.field-block')
        			var result = true;

        			if(_fields.password.value != '') {
        				$block.removeClass('error');
        				$('#oldPassword').val(_fields.password.value);
        			}else {
        				$(_fields.password).focus();
        				$block.addClass('error').find('.invalid').show().siblings().hide();
        				result = false;
        			}

        			return result;
        		},
        		beforeSubmit: function(e){
        			e.preventDefault();

        			var _this = e.currentTarget;
        			
        			if($(_this).checkValidation() && empCombineForm.checkPassword()) {
        				var url = empCombineForm.el.getAttribute('data-url');
        				if(!url){
        			 		setTimeout(function() {
        			 			_this.submit();
        					}, 100);
        				}else{
        					var thirdParty = $('input[name=userIdType]').val()==null ? "" : $('input[name=userIdType]').val();
        					var param = xssfilter($(empCombineForm.el).serialize());
        					var methodType = $(empCombineForm.el).data('ajaxMethod');
        					$("#txtBoxPasswd").siblings('.error-msg').find('span').hide();
        					$('body').trigger('ajaxLoadBefore');
        					
        					if(thirdParty == 'GGL' || thirdParty == 'AMZ' || thirdParty == 'APPL' || thirdParty == 'FBK') {
        						var thirdpartyUrl = empCombineForm.el.getAttribute('data-thirdparty');
        						ajax.noCacheCall(thirdpartyUrl, param, 'json', function(data){
        							$('body').trigger('ajaxLoadEnd');
//        							console.log(data);
	        					 	if(data.result) {
	        					 		location.href = '/us';
	        					 	} else {
	        					 		$("#txtBoxPasswd").siblings('.error-msg').show().find('.ajax-msg').show();
	        					 	}
        						},methodType);
        					} else {
	        					ajax.noCacheCall(url, param, 'json', function(data){
	        						$('body').trigger('ajaxLoadEnd');
	        					 	if(data.result) {
	        					 		//$(form.accountErrMsg).collapse('show');
	        					 		if(data.lgProfileInfo){
	        					 			$('td.lgEmailAddr').html(data.lgProfileInfo.emailAddr);
	        					 			$('td.lgBirthDate').html(data.lgProfileInfo.birthDate);
	        					 			$('td.lgFirstName').html(data.lgProfileInfo.firstName);
	        					 			$('td.lgLastName').html(data.lgProfileInfo.lastName);
	        					 			$('#txtBoxLgFirstName').val(data.lgProfileInfo.firstName);
	        					 			$('#txtBoxLgLastName').val(data.lgProfileInfo.lastName);
	        					 			$('#write-firstName').val(data.lgProfileInfo.firstName);
	        					 			$('#write-lastName').val(data.lgProfileInfo.lastName);
	        					 		}
	        					 		if(data.empProfileInfo){
	        					 			$('#txtBoxEmpFirstName').val(data.empProfileInfo.firstname);
	        					 			$('#txtBoxEmpLastName').val(data.empProfileInfo.lastname);
	        					 		}
	        					 		if(data.loginSession){
	        					 			$('#empId').val(data.loginSession);
	        					 		}
	        					 		
	        					 		$(".container-fluid.step1").css('display', 'none');
	        					 		$(".container-fluid.step2").css('display', 'block');
	        					 		$(".container-fluid.step3").css('display', 'none');
										
										 $('#membership_information1').prop('checked', true).trigger('change');
	        					 		
	        					 	}else {
	        					 		$("#txtBoxPasswd").siblings('.error-msg').show().find('.ajax-msg').show();
	        					 		
	        					 		/*setTimeout(function() {
	        					 			_this.submit();
	        							}, 100);*/
	        					 	}
	        					 },methodType);
        					}
        				}
        			}
        		},
        		addEvent: function(){
        			$(empCombineForm.el).on({
        				submit: empCombineForm.beforeSubmit
        			});
        			$(empCombineForm.el).on({
        				keydown: function(e){
        					if(e.keyCode==13 && e.currentTarget.value != '') {
        						if(!empCombineForm.checkPassword()) {
        							e.preventDefault();
        						}
        					}
        				},
                        /* Start of LGEUS-11769 */
        				keyup: function (e) {
        					var _this = empCombineForm.el;
        					var _fields = {
        						password: $(_this).find('#txtBoxPasswd').get(0)
        					};
        					var $block = $(_fields.password).parents('.field-block');

        					if (e.currentTarget.value == '') {
        						// remove password requirement
        						$block.removeClass('error');
        					}
        				}
                        /* End of LGEUS-11769 */
        			}, 'input[type="password"]');
        			$("input:radio[name='checkMember']").on({
        				change: function(e){
        					if('lg' == this.value){
					 			$('#write-firstName').val($('#txtBoxLgFirstName').val()).prop('readonly', true);
					 			$('#write-lastName').val($('#txtBoxLgLastName').val()).prop('readonly', true);
        					}else if('emp' == this.value){
					 			$('#write-firstName').val($('#txtBoxEmpFirstName').val()).prop('readonly', true);
					 			$('#write-lastName').val($('#txtBoxEmpLastName').val()).prop('readonly', true);
        					}else{
        						$('#write-firstName').prop('readonly', false);
        						$('#write-lastName').prop('readonly', false);
        					}
        				}
        			});
        			// PJTUSEMP- 1 20200701 이벤트 
        			var empOpenBtn = $('.btn-emp-open-email');
        			var emailBlock = $('.email-block');
        			if( empOpenBtn.length > 0 && emailBlock.length > 0 ) {
        				empOpenBtn.on('click',function(e){
        					e.preventDefault();
        					emailBlock.slideToggle();
        					if ( emailBlock.is('.show') ) {
        						$(this).attr('aria-expanded', true);
        					} else {
        						$(this).attr('aria-expanded', false);
        					}
        				});
        			}
        			$(empCombineForm.reset).on({
        				click: function (e) {
                            e.preventDefault();
                            var _this = document.getElementById('checkPasswordForm');
                            var _fields = {
                    				email: $(_this).find('#txtBoxEmail').get(0),
                    			};
                			var $confirmBlock = $(_fields.email).parents('.field-block');
                            if ($(document.getElementById('txtBoxEmail')).checkValidation()) {
                                var url = _this.getAttribute('reset-url');
                                var param = xssfilter($(_this).serialize());
                                var methodType = $(_this).data('ajaxMethod');

                                $('body').trigger('ajaxLoadBefore');
                                ajax.noCacheCall(url, param, 'json', function (data) {
                                    $('body').trigger('ajaxLoadEnd');
                                    $("#txtBoxEmail").siblings('.error-msg').find('span').hide();
                                    if (data) {
                                    	if(data.returnCode == 200){
                                    		$('#modal_emp_reset_password_success').modal('show');
											emailBlock.slideToggle();
											$(this).attr('aria-expanded', false);
                                    	}else {
                                    		$('#modal_emp_reset_password_fail').modal('show');
                                    	}
                                    }else{
                                    	$('.error-msg .ajax-msg').text('error');
                                		$('.error-msg .ajax-msg').show();
                                    }
                                }, methodType);
                            }
                        }
        			});
        		}
        	};

        	empCombineForm.init();
        }
    
    if (document.querySelector('.container-fluid.step2')) {
        var accountConbineForm = {
			el: document.getElementById('accountCombineAForm'),
			init: function(){
				accountConbineForm.addEvent();
			},
			checkFName: function(){
				var _this = accountConbineForm.el;
				var _fields = {
					firstname: $(_this).find('#txtBoxFName').get(0)
				};
				var $fBlock = $(_fields.firstname).parents('.field-block');
				var result = true;
	
				if(_fields.firstname.value != '' && !accountConbineForm.checkNamePattern(_fields.firstname.value)) {
					$fBlock.removeClass('error');
				}else {
					if(_fields.firstname.value != '') {
						$(_fields.firstname).focus();
						$fBlock.addClass('error').find('.invalid').show().siblings().hide();
					}
					result = false;
				}
				
				return result;
			},
			checkLName: function(){
				var _this = accountConbineForm.el;
				var _fields = {
					lastname: $(_this).find('#txtBoxLName').get(0)
				};
				var $lBlock = $(_fields.lastname).parents('.field-block');
				var result = true;
	
				if(_fields.lastname.value != '' && !accountConbineForm.checkNamePattern(_fields.lastname.value)) {
					$lBlock.removeClass('error');
				}else {
					if(_fields.lastname.value != '') {
						$(_fields.lastname).focus();
						$lBlock.addClass('error').find('.invalid').show().siblings().hide();
					}
					result = false;
				}
	
				return result;
			},
			checkNamePattern: function(val){
				var result = false;
				var n = /[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/;
				result = !n.test(val);
				return result;
			},
			checkPassword: function(){
				var _this = accountConbineForm.el;
				var _fields = {
					password: $(_this).find('#txtBoxPassword').get(0),
					passwordConfirm: $(_this).find('#txtBoxPasswordConfirm').get(0)
				};
				var $block = $(_fields.password).parents('.field-block'),
					$confirmBlock = $(_fields.passwordConfirm).parents('.field-block');
				var result = true;

				if(_fields.password.value != '' && accountConbineForm.checkPasswordPattern(_fields.password.value)) {
					if(_fields.password.value == _fields.passwordConfirm.value) {
						$confirmBlock.removeClass('error');
					}else {
						$(_fields.passwordConfirm).focus();
						$confirmBlock.addClass('error').find('.match').show().siblings().hide();
						result = false;
					}
					$block.removeClass('error');
				}else {
					$(_fields.password).focus();
					$block.addClass('error').find('.invalid').show().siblings().hide();
					result = false;
				}

				return result;
			},
			checkPasswordPattern: function(val){
				var result = false;
				if (val.length > 7) {
					var result = false;
					var falseCnt = 0;
					if (val.length > 7) {
						var n = /^.*(?=.*\d).*$/;
						result = n.test(val);
						if(!result) {
							falseCnt++;
						}
						n = /^.*(?=.*[a-z]).*$/;
						result = n.test(val);
						if(!result) {
							falseCnt++;
						}
						n = /^.*(?=.*[A-Z]).*$/;
						result = n.test(val);
						if(!result) {
							falseCnt++;
						}
						n= /^.*(?=.*[!@#$%^*()\-_=+\\\|\[\]{};:\'",.<>\/?]).*$/;
						result = n.test(val);
						if(!result) {
							falseCnt++;
						}
						
						if(falseCnt > 1) {
							result = false;
						} else {
							result = true;
						}
					}
				}
				return result;
			},
			beforeSubmit: function(e){
				e.preventDefault();

				var _this = e.currentTarget;
				
				if($(_this).checkValidation() && accountConbineForm.checkPassword()) {
					var url = accountConbineForm.el.getAttribute('data-url');
					if(!url){
						setTimeout(function() {
							_this.submit();
						}, 100);
					}else{
						var orgFirstName = $('#write-firstName').val();
						var orgLastName = $('#write-lastName').val();
						if($('#membership_information1').is(':checked')) {
							var lgFirstName = $('#txtBoxLgFirstName').val();
							var lgLastName = $('#txtBoxLgLastName').val();
							if(orgFirstName != lgFirstName && orgLastName != lgLastName) {
								$('#write-firstName').val($('#txtBoxLgFirstName').val());
								$('#write-lastName').val($('#txtBoxLgLastName').val());
								$('#isChanged').val("Y");
							} 
						} else if($('#membership_information2').is(':checked')) {
							var empFirstName = $('#txtBoxEmpFirstName').val();
							var empLastName = $('#txtBoxEmpLastName').val();
							if(orgFirstName != empFirstName && orgLastName != empLastName) {
								$('#write-firstName').val($('#txtBoxEmpFirstName').val());
								$('#write-lastName').val($('#txtBoxEmpLastName').val());
								$('#isChanged').val("Y");
							}
						}

						var param = xssfilter($(accountConbineForm.el).serialize());
						param.oldPassword = $('#checkPasswordForm #txtBoxPasswd').val();
						var methodType = $(accountConbineForm.el).data('ajaxMethod');
						$("#txtBoxPassword").siblings('.error-msg').find('span').hide();
						$('body').trigger('ajaxLoadBefore');
						ajax.noCacheCall(url, param, 'json', function(data){
							$('body').trigger('ajaxLoadEnd');
							if(data.result) {
								aForm.init();

								//$(form.accountErrMsg).collapse('show');
								if(data.profileInfo){
									$('td.combineEmailAddr').html(data.profileInfo.emailAddr);
									$('td.combineBirthDate').html(data.profileInfo.birthDate);
									$('td.combineFirstName').html(data.profileInfo.firstName);
									$('td.combineLastName').html(data.profileInfo.lastName);
								}
								$(".container-fluid.step1").css('display', 'none');
								$(".container-fluid.step2").css('display', 'none');
								$(".container-fluid.step3").css('display', 'block');
								
							}else {
								if(data.resultCode == 8 || data.resultCode == 6){
									$("#txtBoxPassword").siblings('.error-msg').show().find('.invalid').show();
								}else{
									$('#modal_change_my_information_fail').modal('show');
								}
							}
						},methodType);
					}
				}
			},
			addEvent: function(){
				$(accountConbineForm.el).on({
					submit: accountConbineForm.beforeSubmit
				});
				$(accountConbineForm.el).on({
					keydown: function(e){
						if(e.keyCode==13 && e.currentTarget.value != '') {
							
							var elId = $(e.target).attr('id');

							if(elId == "txtBoxPassword") {
								if(!accountConbineForm.checkPassword()) {
									e.preventDefault();
								}
							} else if(elId == "txtBoxLgFirstName") {
								if(!accountConbineForm.checkFName()) {
									e.preventDefault();
								}
							} else if(elId == "txtBoxLgLastName") {
								if(!accountConbineForm.checkLName()) {
									e.preventDefault();
								}
							} else if(elId == "txtBoxEmpFirstName") {
								if(!accountConbineForm.checkFName()) {
									e.preventDefault();
								}
							} else if(elId == "txtBoxEmpLastName") {
								if(!accountConbineForm.checkLName()) {
									e.preventDefault();
								}
							}
						}
					},
					keyup: function (e) {
						var _this = accountConbineForm.el;
						var _fields = {
							password: $(_this).find('#txtBoxPassword').get(0),
							firstname: $(_this).find('#txtBoxLgFirstName').get(0),
							lastname: $(_this).find('#txtBoxLgLastName').get(0),
							empFirstname: $(_this).find('#txtBoxEmpFirstName').get(0),
							empLlastname: $(_this).find('#txtBoxEmpLastName').get(0)
						};
						
						var $block = $(_fields.password).parents('.field-block'),
							$fBlock = $(_fields.firstname).parents('.field-block'),
							$lBlock = $(_fields.lastname).parents('.field-block'),
							$empFBlock = $(_fields.empFirstname).parents('.field-block'),
							$empLBlock = $(_fields.empLlastname).parents('.field-block');
	
						var elId = $(e.target).attr('id');
						
						if(elId == "txtBoxPassword") {
							if (e.currentTarget.value == '' || accountConbineForm.checkPasswordPattern(_fields.password.value)) {
								// remove password requirement
								$block.removeClass('error');
							}
						} else if(elId == "txtBoxLgFirstName") {
							if (e.currentTarget.value == '') {
								// remove password requirement
								$fBlock.removeClass('error');
							}
							if (!accountConbineForm.checkNamePattern(_fields.firstname.value)) {
								var input = _fields.firstname.value;
								$(this).val(input.replace(/[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/g, ''));
							}
						} else if(elId == "txtBoxLgLastName") {
							if (e.currentTarget.value == '') {
								// remove password requirement
								$lBlock.removeClass('error');
							}
							if (!accountConbineForm.checkNamePattern(_fields.lastname.value)) {
								var input = _fields.lastname.value;
								$(this).val(input.replace(/[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/g, ''));
							}
						} else if(elId == "txtBoxEmpFirstName") {
							if (e.currentTarget.value == '') {
								// remove password requirement
								$empFBlock.removeClass('error');
							}
							if (!accountConbineForm.checkNamePattern(_fields.empFirstname.value)) {
								var input = _fields.empFirstname.value;
								$(this).val(input.replace(/[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/g, ''));
							}
						} else if(elId == "txtBoxEmpLastName") {
							if (e.currentTarget.value == '') {
								// remove password requirement
								$empLBlock.removeClass('error');
							}
							if (!accountConbineForm.checkNamePattern(_fields.empLlastname.value)) {
								var input = _fields.empLlastname.value;
								$(this).val(input.replace(/[~!@#$%^&*()_+|<>?:;{}`\-\=\\\,.'"\[\]/]/g, ''));
							}
						}
					}
				}, 'input[type="password"],#txtBoxLgFirstName,#txtBoxLgLastName,#txtBoxEmpFirstName,#txtBoxEmpLastName');

				$('#membership_information1').change(function() {
					$('#info-div-overlay').css({
						'width': $('.right-info-div').outerWidth(),
						'height': $('.right-info-div').outerHeight(),
						'top': $('.right-info-div').offset().top,
						'left': $('.right-info-div').offset().left
					}).show();
					
					$('#txtBoxLgFirstName').removeAttr('disabled');
					$('#txtBoxLgLastName').removeAttr('disabled');
					$('#txtBoxEmpFirstName').attr('disabled', true);
					$('#txtBoxEmpLastName').attr('disabled', true);

					$('.right-radio').addClass("overlay-text");
					$('.left-radio').removeClass("overlay-text");
				});

				$('#membership_information2').change(function() {
					$('#info-div-overlay').css({
						'width': $('.left-info-div').outerWidth(),
						'height': $('.left-info-div').outerHeight(),
						'top': $('.left-info-div').offset().top,
						'left': $('.left-info-div').offset().left
					}).show();

					$('#txtBoxEmpFirstName').removeAttr('disabled');
					$('#txtBoxEmpLastName').removeAttr('disabled');
					$('#txtBoxLgFirstName').attr('disabled', true);
					$('#txtBoxLgLastName').attr('disabled', true);

					$('.left-radio').addClass("overlay-text");
					$('.right-radio').removeClass("overlay-text");
				});
			}
		};

		accountConbineForm.init();

		var aForm = {
			el: document.getElementById('aForm'),
			timerInterval: null,
			totalSeconds: parseInt($('#initSec').val()),
			init: function(){
				aForm.startTimer();
			},
			updateTimerDisplay: function(minutes, seconds) {
				var formattedMinutes = '';
				var formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
				if(minutes == null || minutes <= 0) {
					$('#timer').text(seconds + " seconds");
				} else {
					minutes < 10 ? '0' + minutes : minutes;
					$('#timer').text(formattedMinutes + ':' + formattedSeconds);
				}
			},
			startTimer: function() {
				aForm.timerInterval = setInterval(function() {
					var minutes = Math.floor(aForm.totalSeconds / 60);
					var seconds = aForm.totalSeconds % 60;
	
					aForm.updateTimerDisplay(minutes, seconds);
	
					if (aForm.totalSeconds <= 0) {
						clearInterval(aForm.timerInterval);
						window.location.href = "/us";
					}
	
					aForm.totalSeconds--;
				}, 1000);
			}
		};
	}
	
    if ( !!$('.unregistered-resend').length ){
        $('a.unregistered-resend').on({
            click: function (e) {
                e.preventDefault();
                var _this = $('.unregistered-resend');
                var url =  _this.attr('data-url');
                var email = getUrlParams().emailAddr;
                var methodType = $(_this).data('ajaxMethod');
                $('body').trigger('ajaxLoadBefore');
                ajax.noCacheCall(url, { "emailAddr": decodeURIComponent(xssfilter(email)) }, 'json', function (data) {
                    $('body').trigger('ajaxLoadEnd');
                    //$("#txtBoxEmail").siblings('.error-msg').find('span').hide();
                    if (!data.result) {
                    	$('#systemError').modal('show');
                    }else{
                    	$('#unregistered-resend').modal('show');
                    }
                }, methodType);
            }
        });
        var getUrlParams = function() {
            var params = {};
            window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str, key, value) { params[key] = value; });
            return params;
        };
    }
    
    if ( !!$('.resendVerificationEmail').length ){
        $('a.resendVerificationEmail').on({
            click: function (e) {
                e.preventDefault();
                var _this = $('.resendVerificationEmail');
                var url =  _this.attr('data-url');
                var email = $('.step2 dd.integration-email').text();
                var methodType = $(_this).data('ajaxMethod');
                $('body').trigger('ajaxLoadBefore');
                ajax.noCacheCall(url, { "emailAddr": decodeURIComponent(xssfilter(email)) }, 'json', function (data) {
                    $('body').trigger('ajaxLoadEnd');
                    //$("#txtBoxEmail").siblings('.error-msg').find('span').hide();
                    if (data.mailSendFlag =='Y') {
                    	$('#unregistered-resend').modal('show');
                    }else{
                    	$('#systemError').modal('show');
                    }
                }, methodType);
            }
        });
    }

});